/* eslint-disable react/no-danger */
/* eslint-disable no-undef */
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLink } from '../../hook'
import {
  Button,
  LayoutCheckout,
  Loading,
  Link, SEO,
} from '../../ui'
import useStyles from './checkout-complated-style'

const CheckoutCompletedView = ({
  order,
  pageReady,
  paymentReferenceNumber,
  seoTitle,
}) => {
  // prepare hook
  const { navigate } = useLink()
  const { t } = useTranslation()
  const {
    paymentRequests,
  } = order
  // local variable
  const { referenceNumber } = order

  const handleContinueShopping = () => {
    navigate('/')
  }
  // style
  const styles = useStyles()

  return (
    <LayoutCheckout
      disablePaymentFooter
    >
      <SEO title={seoTitle} />
      {pageReady ? (
        <div className={styles.containerFluid}>
          <div className={styles.container}>
            <h1>{t('screens.checkout.complete.title')}</h1>
            <p className={styles.message}>{t('screens.checkout.complete.thanks')}</p>
            <p className={styles.order}>
              <Link to={`/account/order/${referenceNumber}/`}>
                <Trans i18nKey="screens.checkout.complete.order">
                  Order No.
                  {{ referenceNumber }}
                </Trans>
              </Link>
            </p>
            {
              paymentReferenceNumber && (
                <p className={styles.paymentReferenceNumber}>
                  <Trans i18nKey="screens.checkout.complete.paymentReferenceNumber">
                    Payment Reference No.
                    {{ paymentReferenceNumber }}
                  </Trans>
                </p>
              )
            }
            {
              order.paymentState === 'payment_deferred' && (
                <div
                  className={styles.paymentDescription}
                  dangerouslySetInnerHTML={
                    { __html: _.get(paymentRequests, '[0].paymentProvider.description') }
                  }
                />
              )
            }
            <p className={styles.info}>
              <Trans i18nKey="screens.checkout.complete.info">
                For more information please visit
                <Link to="/account/orders">your account</Link>
                .
              </Trans>
            </p>
            <p className={styles.continue}>
              <Button
                className={styles.continueButton}
                dark
                text={t('screens.checkout.complete.buttons.continueShopping')}
                onClick={handleContinueShopping}
              />
            </p>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </LayoutCheckout>
  )
}

export default CheckoutCompletedView

import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  containerFluid: {
    width: '100%',
    padding: [[15, 20, 0]],
  },
  container: {
    textAlign: 'center',
    '& h1': {
      lineHeight: 1.17,
      fontSize: '2.4rem',
      marginTop: '6rem',
      textTransform: 'uppercase',
    },
  },
  continue: {
    marginBottom: '15rem',
  },
  continueButton: {
    margin: [0, 'auto'],
    width: '23rem',
  },
  message: {
    fontSize: '4rem',
    marginTop: '6rem',
    fontWeight: 600,
    lineHeight: 1.2,
    textTransform: 'uppercase',
    whiteSpace: 'pre-line',

  },
  order: {
    marginTop: '5.5rem',
    '& a': {
      color: 'currentColor',
    },
  },
  paymentReferenceNumber: {
    margin: 0,
  },
  paymentDescription: {
    backgroundColor: '#f4f4f4',
    padding: 20,
    textAlign: 'center',
    marginBottom: 20,
    maxWidth: 570,
    margin: '0 auto',
    '& p, & span': {
      lineHeight: 'initial',
    },
    '& p': {
      marginBottom: 5,
    },
  },
  info: {
    marginTop: '3.7rem',
    marginBottom: '5rem',
    whiteSpace: 'pre-line',
    '& a': {
      color: 'currentColor',
    },
  },

  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    containerFluid: {
      padding: 0,
      width: '100%',
      textAlign: 'center',
    },
    container: {
      width: 1170,
      margin: [[0, 'auto']],
      display: 'flex',
      flexDirection: 'column',
    },

  },
}))

export default useStyles
